
/* CSS - DemoN - CANCELLARE!!! */


/* CSS - Imports */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap');

/* CSS - Body */


/* CSS - Icons */
.s-icon {         width:48px;  min-width:48px;  height:48px;  fill:#fff;  margin-right:8px;    }
.s-icon.icon-xl{  width:82px;  min-width:82px;  height:82px;    }
.s-icon.icon-lg{  width:56px;  min-width:56px;  height:56px;    }
.s-icon.icon-sm{  width:36px;  min-width:36px;  height:36px;    }
.s-icon.icon-xs{  width:24px;  min-width:24px;  height:24px;    }
.s-icon.icon-xxs{ width:16px;  min-width:16px;  height:16px;    }

.icon-round{           width:30px;  height:30px; margin-right:8px;  border-radius:50%;  background-color:#fff;   display:flex;  justify-content:center;  align-items:center;   }
.icon-round .s-icon{   width:28px;  height:28px; margin-right:0;       }

.input-group-text .s-icon{  fill:var(--primary);   margin-right:0;  }

.icon-round            .s-icon{  fill:var(--primary);    }
.btn-outline-primary   .s-icon{  fill:var(--primary);    }
.text-secondary        .s-icon,
.btn-outline-secondary .s-icon{  fill:var(--secondary);  }
.btn-primary           .s-icon{  fill:#FFF;   }

.text-dark  .s-icon,
.text-dark.s-icon{            fill:var(--dark);   }


.text-info.s-icon,
.border-info   .s-icon,
.alert-info    .s-icon,
.text-info     .s-icon{       fill:var(--info);    }
.text-success.s-icon,
.border-success .s-icon,
.alert-success .s-icon,
.text-success  .s-icon{       fill:var(--success); }
.text-warning.s-icon,
.border-warning .s-icon,
.alert-warning .s-icon,
.text-warning  .s-icon{       fill:var(--warning); }
.text-danger.s-icon,
.border-danger .s-icon,
.alert-danger  .s-icon,
.text-danger   .s-icon{       fill:var(--danger);  }
.text-primary.s-icon,
.text-primary  .s-icon{       fill:var(--primary); }

/* */
.s-image{    		margin-right:8px;    }

/* */
.title-1{   	font-size:3.5rem;   line-height:1.2;  font-weight:600;  }
.title-2{   	font-size:2.5rem;   line-height:1.2;  font-weight:600;  }
.title-3{   	font-size:2rem;  	line-height:1.2;  font-weight:600;  }
.title-4{    	font-size:1.5rem;  line-height:1.2;  font-weight:500;  }
.title-5{   	font-size:1.25rem;  line-height:1.2;  font-weight:500;  }
.title-6{    	font-size:1rem;     line-height:1.5rem; font-weight:600;   }

.link-u{         text-decoration:underline;   }

.title-icon{     display:flex;   justify-content:flex-start;  align-items:center;     }
.text-icon{      display:flex;   justify-content:flex-start;  align-items:center;     }
























/* Header navbar */
.navbar{                  padding:0;  flex-direction:column;   align-items:stretch;    }

/* */
.navbar.fixed-top{      height:70px;     }
.web-view{                padding-top:70px;    min-height:calc(100vh - 32px);	background-color: #F2F7FC;    }

.navbar .nav.navbar-nav{  flex-direction:row;     }

.navbar .nav-link,
.navbar .navbar-brand{      display:flex;   align-items:center;   transition:background-color .3s, color .3s, border-color .3s;  }
.navbar .navbar-brand{      padding:0 16px;  }
.navbar .navbar-brand img{  height:40px;     }
.navbar .navbar-toggler{  	display:none;    }
.navbar .dropdown-ico{      margin-left:8px;  }

.navbar .nav-main > .navbar-nav,
.navbar .nav-main > .navbar-nav > .nav-item,
.navbar .nav-main > .navbar-nav > .nav-item > .nav-link,
.navbar .nav-splitter > .navbar-nav,
.navbar .nav-splitter > .navbar-nav > .nav-item,
.navbar .nav-splitter > .navbar-nav > .nav-item > .nav-link {  height:inherit;   }

/* */
.nav-item.separator{     border-top:1px solid #dfe4f2;  margin:8px 8px 0 8px;  padding-bottom:8px   }
.nav-item.separator *{   display:none; }

/* */
.navbar-main{                          height:inherit;    }
.navbar-main .nav-main{      					 height:inherit;  display:flex;  justify-content:space-between;  align-items:stretch;   padding:0 32px;  }
.navbar-main .nav-main .nav-link{   	 font-size:1.1rem;   }
.navbar-main .nav-splitter{  height:inherit;	flex:1 1 auto;  display:flex; justify-content:space-between; }

/* */
.nav-splitter > .nav .nav-item                    > .nav-link{  padding:12px 16px 0 12px;     }
.nav-splitter > .nav .nav-item.active             > .nav-link{  color:#FFF !important; background-color:var(--blue-1);  }
.nav-splitter > .nav .nav-item:not(.active):hover > .nav-link{  color:#FFF !important; background-color:var(--blue-1);  }

/* */
.nav-item .s-icon{        margin-right:0;  }
.nav-item.item-nd{        font-weight:bold; border-bottom:1px solid var(--light); }
.nav-item.item-profile .s-icon{   border:2px solid #FFFFFF;  border-radius:50%;  display:flex; justify-content: center;  align-items:center;   }
.nav-item.item-notify{            position:relative;    }
.nav-item.item-notify  .badge{    position:absolute;  border-radius:8px; min-width:18px;  height:18px;  line-height:0.8;  top:30%;  right:16px;  }

/* DropDown Hover */
.dd-hover .navbar-nav > .nav-item > .nav-link.active,
.dd-hover .navbar-nav > .nav-item > .nav-link:hover{   text-decoration:underline; }

.dd-hover .dropdown       > .nav{    position:absolute;  background-color:#FFF;  box-shadow:0 15px 15px 5px rgba(0,0,0,.16);
  top:-1500px;  opacity:0;   transform:translateY(-20px);   transition:transform .3s, opacity .3s;
}
.left     .dropdown       > .nav{    left:0px;   }
.right    .dropdown       > .nav{    right:0px;   }

.dd-hover .dropdown:hover > .nav{    top:100%;     opacity:1;             transform:translateY(0px);    }
.dd-hover .dropdown-right > .nav{    right:0;   left:auto;    }

.dd-hover .dropdown > .nav .nav-item{    width:100%;    }
.dd-hover .dropdown > .nav .nav-link{    padding:8px 16px; font-size:1rem; color:var(--primary) !important;   white-space:nowrap;    }

.dd-hover .dropdown > .nav .nav-item:hover,
.dd-hover .dropdown > .nav .nav-item.active{              background-color:var(--light);    }
.dd-hover .dropdown > .nav .nav-item:hover  > .nav-link,
.dd-hover .dropdown > .nav .nav-item.active > .nav-link{  color:#19191a !important;   background-color:transparent;  }
/* */
.dd-hover .dropdown .dropdown-ico {            transition:all .3s;        }
.dd-hover .dropdown:hover > a > .dropdown-ico{ transform:rotate(180deg);  }

.dd-hover .dropdown .navbar-nav .s-icon{        fill:var(--primary);    }


/* Navigatio notify */
.nav-notify{                width:300px;    overflow-y:auto;   }

.nav-notify	.notify-list{               	margin:0; padding:0;  height:400px;   overflow-y:scroll;  width:100%;   }
.nav-notify	.notify-list:is(:empty){    background-color:var(--light);  }

.nav-notify .notify-title,
.nav-notify .notify-item{   display:flex;  flex-wrap:wrap;   justify-content:space-between;  width:100%;  }

.nav-notify .notify-title{  padding:8px 16px;  align-items:center;  }

.nav-notify .notify-option{ position:relative;  flex:0 0 60px;  text-align:right;   }
.nav-notify .option-card{   display:none;  position:absolute;  top:100%;  right:0;  border-radius:8px; background-color:var(--white);  padding:8px;       }
.nav-notify .option-card a{ display:block;  padding:4px 8px; white-space:nowrap;   }
.nav-notify .notify-option:hover > .option-card{   display:block;   }

.nav-notify .notify-item{   padding:8px 16px;    }
.nav-notify .notify-item:not(:first-child){  border-top:1px solid var(--light); }
.nav-notify .notify-label{  flex: 0 0 86%;    cursor:pointer;  padding:0 8px; }
.nav-notify .notify-label:hover{    background-color:var(--light);    }

.nav-notify small{          display:block;  flex-grow:1;  }


/* Mobile */
.navbar-mobile{     position:absolute;   height:inherit;  display:none;    top:0px;  right:0px;   }
.navbar-mobile > a{ display:inline-block;   padding:14px 0 0 0;  margin-right:16px; }

.navbar-mobile.show > .mobile-login{  display:none;  }

.navbar-mobile > .mobile-hamburger{    position:relative;  width:26px;  height:inherit; }
.mobile-hamburger > div{               position:absolute;  width:26px; height:2px;  background-color:#FFF;  border-radius:1px; overflow:hidden;  transition:all .4s;   }
.mobile-hamburger > div:nth-child(1){  top:19px;  transform-origin:0 1px; }
.mobile-hamburger > div:nth-child(2){  top:27px;  opacity:1;  }
.mobile-hamburger > div:nth-child(3){  top:35px;  transform-origin:0 1px; }

.mobile-hamburger[data-toggle="true"] > div{  background-color:var(--primary);   }
.mobile-hamburger[data-toggle="true"] > div:nth-child(1){  top:18px;  transform:rotate(45deg);  }
.mobile-hamburger[data-toggle="true"] > div:nth-child(2){  opacity:0;  }
.mobile-hamburger[data-toggle="true"] > div:nth-child(3){  top:36px;  transform:rotate(-45deg);  }


/* Mobile - Menu */
.nav-mobile{        left:100%;  right:-100%; height:100vh;  position:absolute; display:none;  transition:all .3s;  background-color:rgba(0,0,0,.5);     }
.nav-mobile.show{   left:0;     right:0;  }
.mobile-inner{      display:flex;   flex-direction:column;  height:100%;  overflow-y:auto;  }

.nav-mobile .nav.navbar-nav{      flex-direction:column;   }
.nav-mobile .nav-link{            width:100%;   font-size:1.4rem;  padding:8px;  }
.nav-mobile .nav-link.active{     text-decoration:underline;    }

.nav-mobile .dropdown .navbar-nav{             flex:1 auto;  }
.nav-mobile .dropdown .navbar-nav .nav-link{   font-size:1.1rem;  }

.nav-mobile .dropdown{            flex-wrap:wrap;   }
.nav-mobile .dropdown-ico{        display:none;     }
.nav-mobile .dropdown > .nav.navbar-nav{   padding-left:16px; }
.nav-mobile .dropdown > .nav .separator{   border-top:1px solid rgba(223,228,242, 0.5);  }

.mobile-inner > .bg-white{        padding:48px 16px 16px 16px; }
.mobile-inner > .bg-primary{      padding:16px 16px 16px 0; }

.nav-mobile .bg-white .icon-round{   border:1px solid var(--primary); }

.nav-mobile .close{   font-size:2rem;  color:#FFF;  margin:8px 16px 0 0;    }


/* */
header.sticky .nav-right{    display:none;  }
header.sticky .navbar-main{   height:50px;   }
header.sticky .navbar-main .nav-main .navbar-brand{  		 display:flex;  }
header.sticky .navbar-main .nav-main .navbar-brand img{  height:56px;     }


/* Footer */
.footer a{       color:#FFF;  }
.footer .list-inline-item{              padding:0 16px;   }
.footer .list-inline-item:first-child{  padding-left:0;  }
.footer .list-inline-item:last-child{   padding-right:0; }
/* */
.footer-small{                          padding:16px 0;  }




/* Button */
.btn:not(.dropdown-toggle){      font-weight:bold;   }
.btn[disabled]{		               cursor:not-allowed;		}
.btn:not(.demon-hide) + .btn{    margin-left:8px;  }

/* Button - Icon */
.btn-icon{              display:flex;      }
.btn-icon-solo .s-icon,
.btn-icon      .s-icon{ margin:0;    transition:fill .3s, stroke .3s; }

.btn-icon-solo{         min-width:38px;  min-height:38px;  padding:0px 2px;   }
.btn-icon > span + .s-icon{  margin-right:8px;  }
.btn-icon > .s-icon + span{  margin-left:8px;   }

.btn-icon.btn-primary:not(:disabled):hover 				   .s-icon,
.btn-icon.btn-secondary:not(:disabled):hover 				 .s-icon,
.btn-icon.btn-outline-primary:not(:disabled):hover   .s-icon,
.btn-icon.btn-outline-secondary:not(:disabled):hover .s-icon{    fill:#FFF;  stroke:#FFF;  }

.btn.btn-link{   padding-left:0; padding-right:0;   }



/*
.btn-toolbar > .container{      display:flex;     }
.btn-toolbar > .container > *{  flex:0 0 auto;    }

.btn-toolbar .center{        display:flex;  justify-content:center;    }
.btn-toolbar .right{         display:flex;  justify-content:flex-end;  }

.btn-toolbar  .ml-auto,
.btn-toolbar  .mr-auto,
.btn-toolbar  .m-auto{      flex:0 0 auto;  min-width:33.3334%;   }
.btn-toolbar  .m-auto{      text-align:center;   }
.btn-toolbar  .ml-auto{     text-align:right;   }
.btn-toolbar  .text-right{  flex:0 0 100%;      }
*/

/* */
.btn-toolbar > .container{	display:flex;	flex-direction:row;	justify-content:flex-start;		text-align:center;	}

/* */
.btn-group-left,
.btn-group-center,
.btn-group-right{    flex: 0 1 100%; 	margin:0;		}

.btn-group-left{    	max-width:33%;  align-self:flex-start;  text-align:left; }
.btn-group-center{   align-self:center;      text-align:center;  }
.btn-group-left + .btn-group-center{ max-width:33%;	}
.btn-group-right{    max-width:33%;  align-self:flex-end;    text-align:right;    }


/* Toolbar fixed */
.btn-fixed{                    			   min-height:80px;		padding-top:16px;  }
.btn-fixed > .btn-toolbar{     		height:64px;		padding:8px 0; 	border-radius: 8px;
  background-color:var(--white);  	box-shadow: 0 0 8px var(--gray-light);
}
.btn-fixed.fixed > .btn-toolbar{   position:fixed;    padding:8px 16px;    border-radius:0px; 	right:0; bottom:0; left:0;		}
/*
.btn-fixed{                    padding-top:56px;  }
.btn-fixed > .btn-toolbar{     position:fixed;  right:0; bottom:0; left:0;  padding:8px 16px; background-color:#FFFFFF;   box-shadow:0 0 15px rgba(0,0,0,.16);  }
*/

/* Form - Control */
.form-control{      border-radius:8px;    }

.input-group{				width:auto;			}

.input-group > .form-control{  flex:none;  }

.input-group-append{    margin-left:-1px;     }
.input-group-text{      padding:4px;  background-color:#FFF;   border-left-color:#FFF;   }

.input-group-addon{     display:flex; align-items:center;  padding:4px; border-top:1px solid #ced4da;  border-bottom:1px solid #ced4da; }
.input-daterange .form-control:first-child{            border-right:none;  border-radius:8px 0 0 8px;  }
.input-daterange .input-group-addon + .form-control{   border-left:none;   }

/* Form - Group */
.label-help{           position:relative; margin-right:44px;   }
.label-help .s-icon{   position:absolute; right:-44px;	top:0; }

/* Form -  Group inline */
.group-inline{							display:flex;		flex-flow:row wrap;  align-items:flex-start;		margin-right:-24px;	}
.group-inline	.form-group{	padding-right:24px;		}

/* */
.courtesy{           	display:block;         padding-top:8px;  }
.courtesy > small{   display:inline-block;  font-size:0.8rem;  }

/* Form - Group Btn */
.form-group.group-btn{		padding-top:32px;	}

/* Form - Feedback */
.form-control.is-invalid{				background-image:none;	}
.is-invalid > .form-feedback > .invalid-feedback{  display:block;  }
.is-invalid .input-group-append > .input-group-text{	border-top-color:var(--danger);	 border-right-color:var(--danger);	border-bottom-color:var(--danger);	}
.is-invalid .dropdown-toggle.btn-select{		border-color:var(--danger);			}

/* Form - Checkbox+Radio */
.form-radio,
.form-checkbox {					display:flex;	flex-direction:row;	flex-wrap:wrap;	margin-left:-8px;	margin-right:-8px;	}

.radio,
.checkbox{								flex:0 1 auto;  margin:0;	padding:12px 8px 4px 8px;		}

.radio 	 	> input[type="radio"],
.checkbox > input[type="checkbox"]{		position:absolute !important;	opacity:0;	width:1 !important; height:1 !important;		}

.radio 		> label,
.checkbox >	label{									cursor:pointer;		display:flex;	align-items:center;  justify-content:flex-start;	}

.radio 		>	label::before,
.checkbox >	label::before{						flex:0 0 26px; 		width:26px;  height:22px;	 	content:'';  stroke:var(--gray);
  background-size:22px 22px;  background-repeat:no-repeat;  background-position:left center;
}

.radio 		> label::before{									background-image:url("../assets/icons/sprite.svg#radio-bg");  	       }
.radio 		> input:checked + label::before{			background-image:url("../assets/icons/sprite.svg#radio-check-bg");     }

.checkbox > label::before{									background-image:url("../assets/icons/sprite.svg#checkbox-bg");		     }
.checkbox > input:checked + label::before{			background-image:url("../assets/icons/sprite.svg#checkbox-check-bg");  }

.radio 		>	input + label,
.checkbox >	input + label{									cursor:pointer;			}

.radio 		>	input[disabled] + label,
.radio 		>	input[readonly] + label,
.checkbox >	input[disabled] + label,
.checkbox >	input[readonly] + label{				color:#8f8f94;	cursor:default;		}

.radio 		>	input[disabled] + label::before,
.radio 		>	input[readonly] + label::before,
.checkbox >	input[disabled] + label::before,
.checkbox >	input[readonly] + label::before{		opacity:0.35;				}


/* Form - File */
.form-file{                      }
.form-file input{      width:.1px;  height:.1px; opacity:0; overflow:hidden; position:absolute; z-index:-1;    }

/* */
.file-drag{  			position:relative;			  }
.file-drag label{	display:flex;	flex-direction:column;		align-items: center; 	padding:32px;  cursor:pointer; 	border-radius:16px;  border:2px dashed var(--secondary);  	}
.form-file input{	width:100%;	height:auto; 	top:0; right:0; bottom:0; left:0;	z-index:1; 	}
.file-drag.dragging label{	border:2px solid var(--secondary);  	background-color:var(--light);	}
.file-drag.dragged label{	border:1px solid var(--secondary);  }
.file-drag .s-icon{		 fill:var(--primary);	}

/* */
.input-group .input-group-append .btn{   border-radius:0 8px 8px 0;     }


/* Form - Select */
.btn-select{                 border-radius:8px;  padding:11px 16px;  border: 1px solid #ced4da;  background-color:#FFF; }
.dropdown.bootstrap-select{  display:block;      }

.bootstrap-select      > .dropdown-toggle::after{  transform:rotateX(0deg);    }
.bootstrap-select.show > .dropdown-toggle::after{  transform:rotateX(180deg);  }



/* Form - Readonly */
.form-control[readonly]{    border-style:dashed;  }
.form-control[readonly] + .input-group-append > .input-group-text{ background-color:#e9ecef;  border-style:dashed; border-color:#ced4da;  border-left-color:#e9ecef;  }
.form-control[readonly] + .input-group-append > .input-group-text .s-icon{  fill:#ced4da;  }

input[type="radio"][readonly]:checked + label::before{   border-color:#7C7C7C;  }
input[type="radio"][readonly]:checked + label::after{    background-color:#7C7C7C;  }

/* Form - align */
.tab-content + .btn-toolbar,
fieldset +    .btn-toolbar{           padding-top:16px;  }

.card-footer{                         		padding-top:0px;   }
.card-footer > .btn-toolbar{          padding-top:0px;  padding-bottom:0px;    }

td > .form-group > .form-check{           margin-top:0;     }
td > .form-group > .form-check > label{   margin-bottom:0;  }


/* Form Field - Size Input: 32 + 9 *n */
.form-group,
.form-group .form-control{ max-width:100%; }

/* Form Field - Wide */
.field-wide .form-control{ width:100%;  }

/* Form Field - Size Input - Step di 2 */
.field-2 .form-control{ width:50px !important;  }
.field-4 .form-control{ width:68px !important;  }
.field-6 .form-control{ width:86px !important;  }
.field-8 .form-control{ width:103px !important;  }
.field-10 .form-control{ width:121px !important;  }
.field-12 .form-control{ width:139px !important; }
.field-14 .form-control{ width:156px !important;  }
.field-16 .form-control{ width:173px !important;  }
.field-18 .form-control{ width:191px !important;  }
.field-20 .form-control{ width:208px !important; }
.field-22 .form-control{ width:225px !important;  }
.field-24 .form-control{ width:242px !important;  }
.field-26 .form-control{ width:259px !important;  }
.field-28 .form-control{ width:276px !important; }
.field-30 .form-control{ width:293px !important;  }
.field-32 .form-control{ width:310px !important;  }
.field-34 .form-control{ width:326px !important;  }
.field-36 .form-control{ width:343px !important; }
.field-38 .form-control{ width:360px !important;  }
.field-40 .form-control{ width:376px !important;  }

/* Form Field Size Input - Step di 10 */
.field-50 .form-control{ width:457px !important;  }
.field-60 .form-control{ width:536px !important;  }
.field-70 .form-control{ width:613px !important;  }
.field-80 .form-control{ width:688px !important; }


/* Form Field - Size Select: 32 + 9 + 24 *n */

/* Form Field - Size Select - Step di 2 */
.field-2 .bootstrap-select{  width:74px !important;  }
.field-4 .bootstrap-select{  width:92px !important;  }
.field-6 .bootstrap-select{  width:110px !important;  }
.field-8 .bootstrap-select{  width:128px !important;  }
.field-10 .bootstrap-select{  width:146px !important;  }
.field-12 .bootstrap-select{  width:163px !important;  }
.field-14 .bootstrap-select{  width:181px !important;  }
.field-16 .bootstrap-select{  width:198px !important;  }
.field-18 .bootstrap-select{  width:216px !important;  }
.field-20 .bootstrap-select{  width:233px !important;  }
.field-22 .bootstrap-select{  width:251px !important;  }
.field-24 .bootstrap-select{  width:268px !important;  }
.field-26 .bootstrap-select{  width:285px !important;  }
.field-28 .bootstrap-select{  width:302px !important;  }
.field-30 .bootstrap-select{  width:319px !important;  }
.field-32 .bootstrap-select{  width:336px !important;  }
.field-34 .bootstrap-select{  width:353px !important;  }
.field-36 .bootstrap-select{  width:370px !important;  }
.field-38 .bootstrap-select{  width:387px !important;  }
.field-40 .bootstrap-select{  width:404px !important;  }

/* Form Field Size Select - Step di 10 */
.field-50 .bootstrap-select{  width:489px !important;  }
.field-60 .bootstrap-select{  width:571px !important;  }
.field-70 .bootstrap-select{  width:652px !important;  }
.field-80 .bootstrap-select{  width:732px !important;  }
















/* Card - Nav Tabs */
.card .nav-tabs .nav-link{				color:var(--gray);	}
.card .nav-tabs .nav-link.active{		color:var(--primary);	border-color:transparent;	border-bottom:4px solid var(--primary);		}
.card .nav-tabs{															padding:16px 0 0 0;			}
.card .nav-tabs .nav-link:not(.active):hover,
.card .nav-tabs .nav-link:not(.active):focus,
.card .nav-tabs .nav-item.show .nav-link:not(.active){		border-color:transparent;		}


/* Card */
.card {	    			border: 1px solid var(--gray-light);		background-color: var(--white);			}
.card-header,
.card-footer{		background-color:var(--white);	}

.card-header{		padding-bottom:0;	}
.card-footer{		padding-top:4px;	}

.card-body  hr{	margin-bottom:16px;		}


/* Card Tabs */
.card-tabs{							background-color:transparent;	}

.card-tabs .nav-link{   		 border:1px solid var(--gray-light);	}
.card-tabs .nav-link.active{   border:1px solid var(--gray-light);	}

.card-tabs .nav-tabs{     	z-index:1;   border:none; }
.card-tabs .tab-content{  padding:16px;  border:1px solid var(--gray-light);   border-radius:0 8px 8px 8px;   background-color:var(--white);  }

.card-tabs .nav-tabs .nav-link{    			border-radius:16px 16px 0 0; background-color:var(--shader1);	padding:8px 24px;  position:relative;	 }

.card-tabs .nav-tabs .nav-link:hover,
.card-tabs .nav-tabs .nav-link.active,
.card-tabs .nav-tabs .nav-item.show .nav-link{	background-color:var(--white);    }

.card-tabs .nav-tabs .nav-link{						color:var(--gray);		transition:all .3s;	}
.card-tabs .nav-tabs .nav-link.active{	 		color:var(--dark);		border-bottom-color:var(--white);	}

.card-tabs .nav-tabs .nav-link:not(.active):hover{				color:var(--dark);	}
.card-tabs .nav-tabs .nav-link:not(.active):hover::before{	 background-color:var(--dark);	}


/* Card Search */
.card-search{						margin-top:16px;	}
.card-search .tab-content{		padding:16px 16px 0 16px;				border-bottom:none;	 	border-radius:0 8px 0 0;	 }
.card-search .card-footer{	 	border:1px solid var(--gray-light);		border-top:none;	}

/* Card Detail */
.card-detail{		margin-top:16px;	}











/* Modal */
.modal-footer{      justify-content:space-between;    }
.modal-xl{          max-width:1000px;  }

.close {  font-size:32px;  font-weight:normal;  line-height:0.5;  }

/* Breadcrumb */
.breadcrumb{		font-size:0.8rem;	padding:0 0 6px 0;		margin:0;		background-color:transparent;	}

/* Alert */
.alert{              	background-color:var(--white);      }
.alert.alert-icon{   display:flex;   align-items:center;  }
.alert .icon-ico{    flex:0 1 40px;    }
.alert .icon-text{   flex:0 1 auto;  padding-left:16px;  }
.alert .close{          }

.alert.border-info{     border-left:6px solid var(--info);  }
.alert.border-warning{  border-left:6px solid var(--warning);  }
.alert.border-danger{   border-left:6px solid var(--danger);   }


/* Action Notify */
.action-notify{                      	position:fixed; top:0px;  right:0;    padding:102px 16px 0 0;   }
.action-notify .notify-item{         height:80px; transition:all .4s;        }
.action-notify .alert{               	display:flex;   align-items:center;  max-width:420px;    transition:all .4s;   margin:0;  box-shadow:2px 2px 6px var(--smoke); }
.action-notify .alert .alert-icon{   flex:0 1 40px;    }
.action-notify .alert .alert-text{   flex:0 1 auto;    }
.action-notify .alert .alert-link{   flex:1 0 54px;  padding-left:8px;    border-left:1px solid var(--white);  }

.action-notify .alert{                               transform:translateX(-500px);   opacity:0; }
.action-notify .notify-item.show           		.alert{   transform:translateX(0px);      opacity:1; }
.action-notify .notify-item.show.remove    .alert{   transform:translateY(-100px);   opacity:0; }
.action-notify .notify-item.show.remove{             height:0;  }

.action-notify .notify-item.bg-primary a{   color:#FFFFFF;   }


/* Card notify
.card .notify-list .notify-item{				display:flex;			}
.card .notify-list .notify-item .s-icon{	margin:0;	}
*/


/* Dashboard */
.db-card{		overflow: hidden;	}

.db-item{							   display:flex;		padding:8px 0;	}
.db-item .item-label{			  flex:0 1 100%; 		}
.db-item .item-progress{		flex:0 0 140px; 	position:relative;		overflow:hidden;	border:2px solid var(--gray);	background-color:var(--light);	border-radius:5px;	}
.db-item .item-progress .bar,
.db-item .item-progress .label{			width:140px;	text-align:center;	}
.db-item .item-progress .bar{			position:absolute;	z-index:1;	top:0; 	bottom:0; left:0;	overflow:hidden;	}
.db-item .item-progress .bar span{	 width:140px;	 display:block;	}





/* Laoder Mask */
.loader-mask{						  display:none; position:fixed;	top:50%; left:50%; 	margin:-55px auto auto -55px;			}
.loader-mask-backdrop{		display:none; position:fixed;	top:0; bottom:0; left:0; right:0;		background-color:rgba(0,0,0,0.5);			}
.loader-mask.show,
.loader-mask-backdrop.show{  display:block;  }

/* Loading - Spinner */
.loading-spinner{     width:96px;  height:96px;   margin:auto;  position:relative;    }

.loading-spinner .spinner-bg{    fill:none; stroke:var(--dark); stroke-width:10;  stroke-opacity:0.8;   }
.loading-spinner .spinner{       fill:none; stroke:var(--primary); stroke-width:8;   stroke-linecap:round;  animation:circle-spinner 1s linear infinite;    transform-origin:45px 45px;  }
.loading-spinner .spinner-title{ color:#FFF;   text-shadow:-1px 1px var(--dark);  text-align:center; }

.modal > .loading-spinner{   height:100%;  display:flex;   flex-direction:column;  align-items:center;    justify-content:center;    }

@keyframes circle-spinner {
  0%    { stroke-dasharray:50   220; transform:rotate(-30deg); }
  60%   { stroke-dasharray:180 220;  transform:rotate(10deg); }
  100%  { stroke-dasharray:50   220; transform:rotate(330deg); }
}



/* Login */
.container-login{		position:absolute;	top:0;	right:0;	bottom:32px;	left:0;		background-color:var(--white);	}
.container-login .row{		height:100%;	}





/* Plugin - Datepicker */
.form-control.datepicker{        padding:8px 16px;    }
.datepicker.datepicker-dropdown{ width:320px; }
.datepicker td, .datepicker td{  width:auto;  }
.datepicker td.day{              padding:6px 10px;  }
.datepicker span.year{           padding:0 16px;  }



/* Plugin - Bootstrap Select */
.bootstrap-select{                                                                          max-width:100% !important;  }
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){  width:auto; }

.bootstrap-select .dropdown-menu{    padding:0;  }
.bootstrap-select .dropdown-item{    padding-left:8px; padding-right:8px;  }

.bootstrap-select.disabled .btn.dropdown-toggle.disabled{     background-color:#e9ecef;  opacity:1;  }

.bootstrap-select.readonly .btn.dropdown-toggle.disabled{     border-style:dashed; border-color:#ced4da;    }




/* Z-Index */
.input-group-append{    					z-index:1;   }

.nav-stepper .nav-step::before{   			z-index:1;   }
.nav-stepper .nav-step > div{     			z-index:2; 	 }

.menu-fixed.fixed .menu-fly{ 				z-index:10;  }

.suggestion-box{ 								z-index:10;  }


.btn-fixed > .btn-toolbar{                  z-index:50;  }


.dropdown.bootstrap-select .dropdown-menu{	z-index:1031;  }
.navbar .dropdown > .nav.navbar-nav{	      z-index:1032;  }

.nav-mobile{	          	z-index:1033;  }
.navbar-mobile{	        	z-index:1034;  }
.action-notify{         	z-index:1051;  }

.container-login{			z-index:1051;  }

.loader-mask{						z-index:990201;	}
.loader-mask-backdrop{  	z-index:990200;	}

.round {
  position: relative;
  float: left;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="radio"] {
  visibility: hidden;
}

.round input[type="radio"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="radio"]:checked + label:after {
  opacity: 1;
}

.dashboard{
  background-color: #F0F6FC;
}

@media (max-width: 1399.900px) {



}


@media (max-width: 1199.900px) {



}



@media (max-width: 991.900px) {

  /* */
  header.mobile,
  .navbar-main .nav-main,
  header.mobile .navbar-main{                   height:56px;    }

  header.mobile .navbar-main .nav-main > .nav-splitter{  display:none;   }

  header.mobile .navbar-main{             width:100%;           }
  header.mobile .navbar-main .nav-main{   max-width:none !important;  justify-content:space-between;   }
  header.mobile .navbar-main .navbar-brand{   		display:flex;   }
  header.mobile .navbar-main .navbar-brand img{		height:56px;		}

  /* */
  header.mobile .navbar-toggler{   display:inline-block;  border:none; padding: 0;  }
  header.mobile .nav-mobile{       display:block;       }

  /* Mobile */
  .navbar-mobile{     display:flex;   }

  /* */
  .web-view{           padding-top:56px;  }

  /* */
  .btn-toolbar  .ml-auto,
  .btn-toolbar  .mr-auto,
  .btn-toolbar  .m-auto{  flex:0 0 auto;   }

  /* Home */


}


@media (max-width: 767.900px) {

  /* */
  .title-1{   font-size:2rem;    }     /* font-size: 3.5rem;  */
  .title-2{   font-size:1.8rem;  }    /* font-size: 2.5rem;    */
  .title-3{   font-size:1.6rem;  }   /* font-size: 2rem; */
  .title-4{   font-size:1.3rem;  }   /* font-size: 1.5rem; */
  .title-5{   font-size:1.1rem;  }  /* font-size: 1.25rem; */
  .title-6{   font-size:1rem;    }     /* font-size: 1rem; */

  /* */
  .btn-toolbar  .ml-auto,
  .btn-toolbar  .mr-auto,
  .btn-toolbar  .m-auto{    text-align:left;  flex:0 0 100%; padding-bottom:8px;   }
  .btn-toolbar  * > button{ width:100%;   }

  /* */
  fieldset + fieldset,
  fieldset + .fieldset-title,
  .fieldset-title + .fieldset-title,
  .fieldset-title + fieldset{           margin-top:16px;  }



  /* Form Field Size Input - Step di 10 */
  .field-50{  width:100% !important; }
  .field-60{  width:100% !important; }
  .field-70{  width:100% !important; }
  .field-80{  width:100% !important; }



  /* */
  .btn-toolbar .btn + .btn{      margin-top:16px;  }
  .btn > .text-icon{             justify-content: center;   }

}

@media ( max-width:575.900px ){

  /* */
  .title-1{   font-size:1.6rem;  }     /* font-size: 2.5rem;  */
  .title-2{   font-size:1.4rem;  }    /* font-size: 2rem;    */
  .title-3{   font-size:1.3rem;  }   /* font-size: 1.75rem; */
  .title-4{   font-size:1.2rem;  }   /* font-size: 1.5rem; */
  .title-5{   font-size:1.1rem;  }  /* font-size: 1.25rem; */
  .title-6{   font-size:1rem;    }     /* font-size: 1rem; */

  /* */
  header.mobile .navbar-brand{       line-height:1rem;   }
  header.mobile .navbar-brand span{  display:block; }

  /* */
  fieldset{ 	padding:16px 8px 0 8px; }



  /* Buttons */
  .btn:not(.btn-icon){       padding-right:16px;  padding-left:16px;   }


  /* Form Field - Size Input - Step di 2 */
  .field-34 { width:100% !important; }
  .field-36 { width:100% !important; }
  .field-38 { width:100% !important; }
  .field-40 { width:100% !important; }

  /* Form Field Size Input - Step di 10 */
  .field-50 { width:100% !important; }
  .field-60 { width:100% !important; }


  /* */
  .card-body{		padding-left:8px; 	padding-right:8px;	}

  /* Home */

}

@media( max-width:359.900px ){



}

.bordodati{
  border: 1px solid #ddd;
  padding: 5px;
  font-weight: normal;
  border-radius: 5px;
  margin-left: 5px;
}

