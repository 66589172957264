/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/**
  sovrascrivo le impostazioni di default
 */
.ngb-toasts {
  position: fixed!important;
  top:70px!important;
  right: 0!important;
  margin: .5em!important;
  z-index: 1200!important;
}

/**
  regole per le frecce negli header delle tabelle
 */

.table-responsive TH{
  cursor: pointer;
}

.sortable-down::before {
  position: relative;
  top: -2pt;
  content: "";
  display: inline-block;
  /* By using an em scale, the arrows will size with the font */
  width: 0.4em;
  height: 0.4em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
}

.sortable-up::before {
  position: relative;
  top: -2pt;
  content: "";
  display: inline-block;
  /* By using an em scale, the arrows will size with the font */
  width: 0.4em;
  height: 0.4em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(315deg);
  margin-right: 0.5em;
}


.flowcheckbox{
  padding-right: 10px !important;
  border: none !important;
}

.landingspinner {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
  margin-top: -50px; /* half of the elements height */
  margin-right: -50px; /* half of the elements widht */
}

.range-accordion .btn-link {
  width: 100%;
  text-decoration-line: none;
  background-color: #eee;
  padding: 10px;
  margin-bottom: 10px;
}


app-monitoraggio .card-body {
  padding: 0px !important;
}

app-monitoraggio td {
  padding-top: 0px !important;
  padding-left: 15px !important;
  padding-right: 0px !important;

  padding-bottom: 20px !important;}

.grenabr{
  background-color: #28a745;
}
.yellowabr{
  background-color: #ffc107;
}

.spinner-border.centered{
    display: table;
    margin: auto;
}

ngb-typeahead-window{
  overflow: auto;
  height: 300px;
}
#storicoanagrafica-header {
  background-color: #eee;
  color: #888;
}
.white-svg{
  filter: invert(100%) sepia(1%) saturate(7500%) hue-rotate(186deg) brightness(101%) contrast(101%);

}
.sismModal .modal-dialog  {
  max-width: 90%;
  width: 90%;
}

.custom-day.focused {

}
.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}
